@use "reset";
@use "mixin";
@use "animation";

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    background: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-family: arial, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, メイリオ, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "MS PGothic", Osaka,
        sans-serif;
    color: #505050;
    line-height: 1.75;
    overflow-x: hidden;

    @include mixin.width1000 {
        &.fixed {
            height: 100%;
            overflow: hidden;
        }
    }

    @include mixin.width768 {
        font-size: 1.4rem;
    }
}

a {
    color: mixin.$blue1;
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover {
        text-decoration: none;
    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

p {
    margin: 0 0 30px;
}

ul,
ol,
li {
    list-style: none;
}

button {
    transition: all 0.3s ease;

    &:hover {
        cursor: pointer;
    }
}

.container {
    max-width: 1000px;
    width: 100%;
    @include mixin.container_margin;

    @include mixin.width1000 {
        width: calc(100% - 80px);
        @include mixin.container_margin(40px);
    }

    @include mixin.width768 {
        width: calc(100% - 40px);
        @include mixin.container_margin(20px);
    }
}
.pc {
    display: block;

    @include mixin.width768 {
        display: none;
    }
}
.tb {
    display: none;

    @include mixin.width768 {
        display: block;
    }
}

.sp {
    display: none;

    @include mixin.width480 {
        display: block;
    }
}

.anim {
    opacity: 0;

    &.bottom {
        transform: translateY(50px);

        &.is-act {
            animation: bottom-fadein 0.5s ease 0.1s forwards;
        }
    }

    &.left {
        transform: translateX(-50px);

        &.is-act {
            animation: left-fadein 0.5s ease 0.1s forwards;
        }
    }

    &.right {
        transform: translateX(50px);

        &.is-act {
            animation: right-fadein 0.5s ease 0.1s forwards;
        }
    }

    &.scale {
        transform: scale3d(1.2, 1.2, 1.2);

        &.is-act {
            animation: scale-fadein 1s ease 0.1s forwards;
        }
    }

    &.fadein {
        opacity: 0;

        &.is-act {
            animation: fadein 1s ease 0.1s forwards;
        }
    }

    &-list {
        & > * {
            opacity: 0;

            &.is-act {
                animation: fadein 0.5s ease 0s forwards;
            }
        }
    }
}

header {
    .header-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3000;
        //transition: all 0.3s ease;
        width: 100%;
        height: 80px;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #logo {
            max-width: 72px;
            margin: 0;
        }

        #menu-btn {
            display: none;
        }
    }

    @include mixin.width1000 {
        .header-container {
            #logo {
                max-width: 72px;
                margin: 0;
            }

            #menu-btn {
                display: block;
                width: 40px;
                height: 40px;
                padding: 5px 0;
                position: fixed;
                top: 20px;
                right: 40px;
                z-index: 5000;
                cursor: pointer;

                & > span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    span {
                        display: block;
                        width: 100%;
                        height: 2px;
                        background: #000;
                        border-radius: 2px;
                        position: absolute;
                        right: 0;
                        transition: all 0.3s ease;
                        transform-origin: center;

                        &:first-child {
                            top: 0;
                        }

                        &:nth-child(2) {
                            top: 14px;
                            opacity: 1;
                        }

                        &:nth-child(3) {
                            top: 28px;
                        }
                    }
                }

                &.open {
                    & > span {
                        span {
                            background: #fff;

                            &:first-child {
                                top: 14px;
                                transform: rotate(-45deg);
                            }

                            &:nth-child(2) {
                                width: 0;
                                opacity: 0;
                            }

                            &:nth-child(3) {
                                top: 14px;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
            }

            &.open {
                right: 0;
            }
        }
    }

    @include mixin.width768 {
        .header-container {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            #logo {
                max-width: 40px;
                margin: 0;
            }

            #menu-btn {
                padding: 10px;
                top: 5px;
                right: 10px;

                & > span {
                    span {
                        &:first-child {
                            top: 2px;
                        }

                        &:nth-child(2) {
                            top: 10px;
                        }

                        &:nth-child(3) {
                            top: 18px;
                        }
                    }
                }

                &.open {
                    & > span {
                        span {
                            &:first-child {
                                top: 10px;
                            }

                            &:nth-child(3) {
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.hide {
        .header-container {
            position: fixed;
            top: 0;
            left: 0;
            transform: translateY(-100%);
        }
    }

    &.fixed {
        .header-container {
            background: #fff;
            //transition: all 0.3s ease;
            transform: translateY(0);
            z-index: 5000;
            @include mixin.width1000 {
                background: none;
            }
        }
    }
}

main {
    #g-navi {
        background: #fff;
        padding: 30px 50px;
        ul {
            max-width: 1000px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            li {
                font-size: clamp(1.4rem, 1vw, 1.6rem);
                text-align: center;
                line-height: 1.4;
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    color: #000;
                    text-decoration: none;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: mixin.$blue1;
                    }
                }
                &.contact-btn {
                    position: relative;
                    top: -1px;
                    a {
                        display: block;
                        height: 40px;
                        line-height: 40px;
                        background: mixin.$yellow1;
                        border-radius: 20px;
                        color: #000;
                        position: relative;
                        padding: 0 30px;
                        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.35);

                        &:hover {
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        @include mixin.width1000 {
            background: #505050;
            position: fixed;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100dvh;
            z-index: 2500;
            padding: 100px 20px;
            transition: all 0.3s ease;
            overflow-y: scroll;

            ul {
                display: block;

                li {
                    font-size: clamp(1.4rem, 1.8vw, 2rem);
                    margin: 0 auto 20px;

                    a {
                        color: #000;
                        text-decoration: none;
                        display: block;
                        height: auto;
                        padding: 10px;
                        color: #fff;

                        br {
                            display: none;
                        }

                        &:hover {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.open {
                right: 0;
            }
        }
        @include mixin.width768 {
            padding: 60px 20px;
        }
    }
    & > * {
        width: 100%;
        padding: 100px 0;
        overflow-x: hidden;
        .tit-h {
            font-size: clamp(1.8rem, 2vw, 2.4rem);
            text-align: center;
            margin: 0 auto 6rem;
            font-weight: 500;
            .arial {
                font-size: clamp(3.6rem, 3.8vw, 4.5rem);
                display: block;
                font-weight: 900;
                line-height: 1;
                font-family: "Arial Black", Arial, Helvetica, sans-serif;
            }

            @include mixin.width768 {
                font-size: clamp(1.4rem, 1.5vw, 1.6rem);
                margin: 0 auto 4rem;
                span {
                    font-size: clamp(2.8rem, 3vw, 3.2rem);
                }
            }
        }

        &#mv {
            width: 100%;
            height: 646px;
            background-image: url(../images/bg_mv_pc.png);
            background-image: image-set(url(../images/bg_mv_pc.png) 1x, url(../images/bg_mv_pc@2x.png) 2x);
            background-image: -webkit-image-set(url(../images/bg_mv_pc.png) 1x, url(../images/bg_mv_pc@2x.png) 2x);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .container {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;
                h1 {
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 1;
                    margin: 0;
                    color: #fff;
                    .arial {
                        display: block;
                        font-family: "Arial Black", Arial, Helvetica, sans-serif;
                        font-size: clamp(5rem, 6vw, 8rem);
                        font-weight: 900;
                        span {
                            font-size: clamp(8rem, 8vw, 11rem);
                        }
                    }
                }
            }
            @include mixin.width768 {
                width: 100%;
                height: 100svh;
                background-image: url(../images/bg_mv_sp.png);
                background-image: image-set(url(../images/bg_mv_sp.png) 1x, url(../images/bg_mv_sp@2x.png) 2x);
                background-image: -webkit-image-set(url(../images/bg_mv_sp.png) 1x, url(../images/bg_mv_sp@2x.png) 2x);
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                .container {
                    justify-content: start;
                    align-items: center;
                    height: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h1 {
                        font-size: 1.6rem;
                    }
                }
            }
        }
        &#about {
            background: mixin.$blue1;
            text-align: center;
            .tit-h {
                color: #fff;
            }
            p {
                font-size: clamp(1.6rem, 1.8vw, 2rem);
                color: #fff;
                .line_y {
                    color: mixin.$yellow1;
                }
            }
        }
        &#functions {
            background: #f8f7f5;
            .container {
                ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    li {
                        width: 30%;
                        gap: auto 50px;
                        .img {
                            display: block;
                            max-width: 240px;
                            margin: 0 auto 30px;
                            border-radius: 50%;
                            position: relative;
                            border: 1px solid #000;
                            img {
                                border-radius: 50%;
                                width: 100%;
                            }
                        }
                        h3 {
                            text-align: center;
                            font-size: clamp(1.8rem, 1.8vw, 2rem);
                        }
                        p {
                            font-size: clamp(1.3rem, 1.4vw, 1.5rem);
                            text-align: justify;
                            text-align: center;
                        }
                    }
                }
            }
            @include mixin.width768 {
                .container {
                    ul {
                        li {
                            width: 48%;
                            gap: auto 50px;
                            .img {
                                width: 80%;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
            @include mixin.width480 {
                .container {
                    ul {
                        li {
                            width: 100%;
                            gap: auto 50px;
                            .img {
                                width: 80%;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
        }
        &#price {
            .container {
                .base-price {
                    background: mixin.$blue1;
                    border-radius: 15px;
                    padding: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: stretch;
                    color: #000;
                    margin-bottom: 50px;
                    h3 {
                        background: #fff;
                        color: mixin.$blue1;
                        font-weight: 700;
                        font-size: clamp(2.4rem, 2.5vw, 3rem);
                        text-align: center;
                        padding: 10px 50px;
                        margin: 0 50px 0 0;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                    }
                    ul {
                        li {
                            font-size: clamp(2.4rem, 2.5vw, 3rem);
                            font-weight: 500;
                            margin: 0;
                            line-height: 1;
                            color: #fff;
                            text-align: right;
                            color: mixin.$yellow1;
                            span {
                                display: inline-block;
                                &.tax {
                                    font-size: 0.5em !important;
                                }
                            }
                            &.tax-in {
                                span {
                                    font-size: clamp(2.4rem, 2.5vw, 3rem);
                                }
                            }
                            &.tax-sep {
                                span {
                                    font-size: clamp(4rem, 5vw, 6.6rem);
                                }
                            }
                        }
                    }
                }
                ul.options {
                    display: grid;
                    grid-template-columns: 32% 32% 32%;
                    gap: 2%;
                    & > li {
                        padding: 20px;
                        display: grid;
                        grid-row: span 4;
                        grid-template-rows: subgrid;
                        border-radius: 15px;
                        border: 3px solid mixin.$blue1;
                        h3 {
                            text-align: center;
                            font-size: clamp(2rem, 2vw, 2.4rem);
                            font-weight: 700;
                            margin: 0 auto 2rem;
                        }
                        .lead {
                            text-align: center;
                            font-size: clamp(1.5rem, 1.6vw, 1.8rem);
                        }
                        dl {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            margin: 0 auto 30px;
                            dt {
                                font-size: clamp(1.4rem, 1.5vw, 1.6rem);
                                font-weight: 700;
                                width: calc(100% - 4em);
                                position: relative;
                                padding-left: 18px;
                                &:before {
                                    content: "";
                                    display: block;
                                    width: 15px;
                                    height: 15px;
                                    background: #505050;
                                    border-radius: 50%;
                                    position: absolute;
                                    top: 5px;
                                    left: 0;
                                }
                                ul {
                                    li {
                                        font-weight: 500;
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                            dd {
                                font-size: clamp(1.4rem, 1.5vw, 1.6rem);
                                width: 4em;
                                text-align: right;
                            }
                        }
                        .price {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-top: 1px solid #505050;
                            padding: 10px 0 0 0;
                            h4 {
                                font-size: clamp(2rem, 2.3vw, 2.4rem);
                                margin: 0;
                                line-height: 1;
                            }
                            p {
                                font-size: clamp(1.6rem, 1.6vw, 1.8rem);
                                margin: 0;
                                line-height: 1;
                                span {
                                    font-size: clamp(2.8rem, 2.8vw, 3rem);
                                }
                            }
                        }
                    }
                }
            }
            @include mixin.width768 {
                .container {
                    .base-price {
                        padding: 20px;
                        flex-direction: column;
                        gap: 20px;
                        margin-bottom: 30px;
                        h3 {
                            padding: 8px 0;
                            justify-content: center;
                            margin: 0;
                        }
                        p {
                            justify-content: end;
                        }
                    }
                    ul.options {
                        display: block;
                        & > li {
                            width: 100%;
                            position: relative;
                            padding: 20px;
                            margin-bottom: 15px;
                            display: block;
                        }
                    }
                }
            }
        }
        &#company {
            background: #f2f3f4;
            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                .tit-h {
                    width: 100%;
                }
                .txt {
                    width: 49%;
                    h3 {
                        font-size: 2rem;
                        font-weight: 700;
                        margin: 0 auto 3rem;
                    }
                    table {
                        width: 100%;
                        border: 0;
                        border-collapse: collapse;
                        th,
                        td {
                            border: 0;
                            padding: 10px 0;
                        }
                        td {
                            padding: 10px 0 10px 10px;
                        }
                    }
                }
                .map-box {
                    width: 50%;
                    padding: 36% 0 0 0;
                    position: relative;
                    z-index: 5;
                    border: 1px solid #000;
                    border-radius: 15px;
                    overflow: hidden;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                    }
                }
            }
            @include mixin.width768 {
                .container {
                    flex-direction: column;
                    gap: 30px;
                    .tit-h {
                        width: 100%;
                    }
                    .txt {
                        width: 100%;
                        table {
                            width: 100%;
                            border: 0;
                            border-collapse: collapse;
                            th,
                            td {
                                display: block;
                                width: 100%;
                                padding: 10px 0 10px;
                            }
                            th {
                                font-weight: 700;
                            }
                            td {
                                border-bottom: 1px solid #505050;
                                padding: 0 0 10px 0;
                            }
                        }
                    }
                    .map-box {
                        width: 98%;
                        padding: 98% 0 0 0;
                        position: relative;
                        z-index: 5;
                        &:before {
                            transform: scale(1.03, 1.03);
                        }
                    }
                }
            }
        }
        &#contact {
            background: mixin.$blue1;

            .container {
                background: #fff;
                padding: 50px;
                border-radius: 20px;
                @include mixin.width1000 {
                    @include mixin.section_padding(40px);
                }

                @include mixin.width768 {
                    @include mixin.section_padding(20px);
                }
                p {
                    text-align: center;
                    margin: 0 auto 60px;
                }

                .red {
                    color: #eb3333;
                }

                table {
                    width: 100%;
                    border: 0;
                    border-collapse: collapse;
                    font-size: 1.8rem;
                    margin: 0 auto 30px;

                    th {
                        border: 0;
                        width: 280px;
                        padding: 15px 0;
                        vertical-align: top;
                        font-weight: 700;
                    }

                    td {
                        border: 0;
                        padding: 15px 0;
                        vertical-align: top;
                    }
                }

                input[type="text"] {
                    background: #fff;
                    width: 100%;
                    height: 40px;
                    border: 1px solid #000;
                    border-radius: 4px;
                    line-height: 38px;
                    padding: 0 10px;
                }
                ::placeholder {
                    color: #ccc;
                }
                textarea {
                    background: #fff;
                    width: 100%;
                    min-height: 200px;
                    border: 1px solid #000;
                    border-radius: 4px;
                    padding: 5px 10px;
                }

                label.checkbox-text {
                    cursor: pointer;
                    display: inline-block;
                    font-size: 1.8rem;
                    min-height: 26px;
                    overflow: hidden;
                    padding-left: 25px;
                    padding-left: 30px;
                    position: relative;

                    &:before {
                        border: 1px solid #000;
                        border-radius: 2px;
                        content: "";
                        height: 24px;
                        left: 0px;
                        position: absolute;
                        top: 0;
                        width: 24px;
                        z-index: 3;
                    }

                    &:after {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        border-bottom: 3px solid mixin.$blue1;
                        border-right: 3px solid mixin.$blue1;
                        content: "";
                        display: block;
                        height: 14px;
                        left: 6px;
                        margin-top: -8px;
                        position: absolute;
                        top: 10px;
                        transform: rotate(45deg);
                        width: 10px;
                        z-index: 1;
                    }

                    & input[type="checkbox"] {
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        -webkit-box-shadow: 40px 0px #fff;
                        box-shadow: 40px 0px #fff;
                        display: block;
                        height: 24px;
                        left: -40px;
                        margin: 0px;
                        padding: 0px;
                        position: absolute;
                        width: 24px;
                        z-index: 2;
                    }

                    & input[type="checkbox"]:checked {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        z-index: -1;
                    }

                    & input[type="checkbox"]:checked:focus {
                        -webkit-box-shadow: 40px 0px #fff;
                        box-shadow: 40px 0px #fff;
                        opacity: 0.1;
                    }

                    & input[type="checkbox"]:focus {
                        -webkit-box-shadow: 41px 0px #fff;
                        box-shadow: 41px 0px #fff;
                    }
                }

                label.radio-text {
                    cursor: pointer;
                    position: relative;
                    margin-right: 20px;
                    overflow: hidden;
                    padding-left: 30px;
                    display: inline-block;

                    &:before {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        border: 1px solid #000;
                        border-radius: 50%;
                        left: 0px;
                        top: 4px;
                        content: "";
                        z-index: 3;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        left: 6px;
                        top: 10px;
                        background-color: mixin.$blue1;
                        z-index: 1;
                    }

                    & input[type="radio"] {
                        appearance: none;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        position: absolute;
                        z-index: 2;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        left: -24px;
                        top: 1px;
                        margin: 0px;
                        box-shadow: 24px 3px #fff;
                    }

                    & input[type="radio"]:checked {
                        box-shadow: none;
                    }

                    & input[type="radio"]:focus {
                        opacity: 0.2;
                        box-shadow: 24px -1px #fff;
                    }
                }

                .privacy-area {
                    .privacy-policy {
                        border: 1px solid #000;
                        padding: 10px;
                        text-align: left;
                        height: 300px;
                        overflow-y: scroll;
                        transform: translateZ(0);
                        word-wrap: break-word;
                        margin-bottom: 30px;
                        .scroll-box {
                            width: 100%;
                            p {
                                text-align: left;
                                margin-bottom: 30px;
                            }
                            dl {
                                dt {
                                    clear: both;
                                    float: left;
                                }
                                dd {
                                    float: left;
                                }
                                &:after {
                                    content: "";
                                    display: table;
                                    clear: both;
                                }
                            }
                        }
                    }
                    .checkbox-text {
                        text-align: center;
                    }
                }

                .btn-area {
                    text-align: center;
                    padding: 30px 0;
                    display: flex;
                    justify-content: center;
                    gap: 30px;

                    button {
                        width: 50%;
                        max-width: 320px;
                        height: 60px;
                        line-height: 58px;
                        font-size: 1.8rem;

                        &.btn-submit {
                            background: mixin.$yellow1;
                            border-radius: 10px;
                            color: #000;
                            position: relative;
                            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
                            &:hover {
                                box-shadow: none;
                            }

                            &[disabled] {
                                background: #666;
                                cursor: not-allowed;
                                box-shadow: none;
                                &:hover {
                                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
                                }
                            }
                        }
                        &.btn-back {
                            border: 3px solid #000;
                            color: #000;
                            position: relative;
                        }
                    }
                }
            }

            @include mixin.width768 {
                .container {
                    table {
                        th {
                            display: block;
                            width: 100%;
                            padding: 15px 0 0;
                        }

                        td {
                            display: block;
                            width: 100%;
                            padding: 10px 0;

                            ul {
                                flex-direction: column;
                                gap: 10px;
                                flex-wrap: wrap;
                            }
                        }
                    }

                    .btn-area {
                        flex-direction: column-reverse;

                        button {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }

    @include mixin.width1000 {
        & > section {
            padding: 100px 0;
        }
    }

    @include mixin.width768 {
        & > section {
            padding: 80px 0;
        }
    }

    @include mixin.width480 {
        & > section {
            padding: 40px 0;
        }
    }
}

footer {
    background: #fff;
    padding: 60px 0 20px;

    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        #f-navi {
            ul {
                display: flex;
                justify-content: end;
                gap: 30px;
                margin-bottom: 30px;
            }
        }

        #copyright {
            width: 100%;
            text-align: center;
            font-size: 1.4rem;
        }
    }

    @include mixin.width1000 {
        & > section {
            padding: 100px 0;
        }
    }

    @include mixin.width768 {
        .container {
            flex-direction: column;
            align-items: center;
        }
    }
}
