$blue1: #19527c;
$yellow1: #e6d826;

$w1400: 1400px;
$w1200: 1280px;
$w1000: 1000px;
$w768: 768px;
$w480: 480px;
$w375: 374px;

@mixin width1400 {
    @media screen and (min-width: ($w1400)) {
        @content;
    }
}

@mixin width1200 {
    @media screen and (max-width: ($w1200)) {
        @content;
    }
}

@mixin width1000 {
    @media screen and (max-width: ($w1000)) {
        @content;
    }
}

@mixin width768 {
    @media screen and (max-width: ($w768)) {
        @content;
    }
}

@mixin width480 {
    @media screen and (max-width: ($w480)) {
        @content;
    }
}

@mixin width375 {
    @media screen and (max-width: ($w375)) {
        @content;
    }
}

@mixin container_margin($margin: auto) {
    margin: 0 $margin;
}

@mixin section_padding($padding: auto) {
    padding: $padding;
}
