@keyframes tit-fadein {
  0% {
    background-size: 110% auto;
  }

  100% {
    background-size: 100% auto;
  }
}

@keyframes scale-fadein {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale3d(1.2, 1.2, 1.2);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@keyframes bottom-fadein {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes left-fadein {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes right-fadein {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}